import React, { useMemo, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import KneeScoreRangeSlider from "../../../custom/knee-score-range-slider/KneeScoreRangeSlider";
// import RangeSlider from "../../../custom/range-slider/RangeSlider";
import FullDropdown from "../../../custom/dropdown/FullDropdown";
import Checkbox from "../../../custom/checkbox/Checkbox";
import InputField from "../../../custom/inputfield/InputField";
import HandWristTab from "../../tab-section/HandWristTab";
import { useSearchParams } from "react-router-dom";
import {
  updateHandWristScores,
  updateHandWristfunctionFieldScore,
  updateHandWristfunctionScores,
  updateHandWristPainScores,
  updateHandWristSymptomsScores,
  updateHandWristqolScores,
  updateHandWristsatisfactionScores,
  updateHandWristFeedbackScores,
  updateHandWristUramScores,
  updateHandWristbctqScores,
  updateAllData,
} from "../../../../redux/actions/handWrist";
import useAxios from "../../../../axiosinstance";
import useTranslation from "../../../customHooks/translations";
import CustomToaster from "../../../custom/toaster/CustomToaster";
import HandWristScoreRangeSlider from "../../../custom/hand-wrist-function-score-range-slider/HandWristScoreRangeSlider";
import {
  LeftSide,
  LeftTrans,
  RightSide,
  RigthTrans,
} from "../../../../utilities/RightLeftFormat";
import NameCustom from "../../../custom/name/NameCustom";

function HandWristfunction() {
  const translation = useTranslation();
  let lg = sessionStorage.getItem("language");
  const dispatch = useDispatch();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const axiosinstance = useAxios();
  const [isLoading, setIsLoading] = useState(true);
  const [overallR, setOverallR] = useState("0");
  const [overallL, setOverallL] = useState("0");
  const [oftenR, setOftenR] = useState("0");
  const [oftenL, setOftenL] = useState("0");
  const [longerR, setLongerR] = useState("0");
  const [longerL, setLongerL] = useState("0");
  const [tightR, setTightR] = useState("0");
  const [tightL, setTightL] = useState("0");
  const [fryingR, setFryingR] = useState("0");
  const [fryingL, setFryingL] = useState("0");
  const [buttonR, setButtonR] = useState("0");
  const [buttonL, setButtonL] = useState("0");
  const [householdR, setHouseholdR] = useState("0");
  const [householdL, setHouseholdL] = useState("0");
  const [carryR, setCarryR] = useState("0");
  const [carryL, setCarryL] = useState("0");
  const [washR, setWashR] = useState("0");
  const [washL, setWashL] = useState("0");
  const [knifeR, setKnifeR] = useState("0");
  const [knifeL, setKnifeL] = useState("0");
  const [activitiesR, setActivitiesR] = useState("0");
  const [activitiesL, setActivitiesL] = useState("0");
  const [sleepR, setSleepR] = useState("0");
  const [sleepL, setSleepL] = useState("0");
  const [usualR, setUsualR] = useState("0");
  const [usualL, setUsualL] = useState("0");
  const [workR, setWorkR] = useState("0");
  const [workL, setWorkL] = useState("0");
  const [spendingR, setSpendingR] = useState("0");
  const [spendingL, setSpendingL] = useState("0");
  const [instrumentR, setInstrumentR] = useState("0");
  const [instrumentL, setInstrumentL] = useState("0");
  const [playingR, setPlayingR] = useState("0");
  const [playingL, setPlayingL] = useState("0");
  const [spendR, setSpendR] = useState("0");
  const [spendL, setSpendL] = useState("0");
  const [socialR, setSocialR] = useState("0");
  const [socialL, setSocialL] = useState("0");
  const [limitR, setLimitR] = useState("0");
  const [limitL, setLimitL] = useState("0");
  const [SpecifyR, setSpecifyR] = useState("0");
  const [SpecifyL, setSpecifyL] = useState("0");
  const [checkR, setCheckR] = useState(false);
  const [checkL, setCheckL] = useState(false);
  const [textR, setTextR] = useState("");
  const [textL, setTextL] = useState("");

  const [SpecifysportR, setSpecifysportR] = useState("0");
  const [SpecifysportL, setSpecifysportL] = useState("0");
  const [checksportR, setChecksportR] = useState(false);
  const [checksportL, setChecksportL] = useState(false);
  const [textsportR, setTextsportR] = useState("");
  const [textsportL, setTextsportL] = useState("");

  const [syndrome, setSyndrome] = useState("0");
  const [disease, setDisease] = useState("0");

  const navigate = useNavigate();
  const evaluated = useSelector((state) => state.handWrist.evaluated);
  const [chk, setChk] = useState(false);
  const [updateTotal, setUpdateTotal] = useState(false);
  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=23`)
      .then((res) => {
        console.log(res.data.data2);
        setSyndrome(res.data.data2[0].isSyndrome);
        setDisease(res.data.data2[0].isDisease);
        dispatch(updateHandWristPainScores(res.data.data2[0].painScore));
        dispatch(
          updateHandWristSymptomsScores(res.data.data2[0].symptomsScore)
        );
        dispatch(updateHandWristScores(res.data.data2[0].evaluated));
        dispatch(
          updateHandWristfunctionScores(res.data.data2[0].functionScore)
        );
        dispatch(updateHandWristqolScores(res.data.data2[0].qolScore));
        dispatch(updateHandWristUramScores(res.data.data2[0].uramScore));
        dispatch(updateHandWristbctqScores(res.data.data2[0].bctq));

        dispatch(
          updateHandWristsatisfactionScores(res.data.data2[0].satisfactionScore)
        );
        dispatch(
          updateHandWristFeedbackScores(res.data.data2[0].doctorFeedback)
        );
        setChk(true);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };

  useEffect(() => {
    save();
   
  }, []);
  const Handfunction = useSelector((state) => state.handWrist.functionScore[0]);
  const json = useSelector((state) => state.handWrist);

  useEffect(() => {
    setOverallR(Handfunction.overallR);
    setOverallL(Handfunction.overallL);
    setOftenR(Handfunction.oftenR);
    setOftenL(Handfunction.oftenL);
    setLongerR(Handfunction.longerR);
    setLongerL(Handfunction.longerL);
    setTightR(Handfunction.tightR);
    setTightL(Handfunction.tightL);
    setFryingR(Handfunction.fryingR);
    setFryingL(Handfunction.fryingL);
    setButtonR(Handfunction.buttonR);
    setButtonL(Handfunction.buttonL);
    setHouseholdR(Handfunction.householdR);
    setHouseholdL(Handfunction.householdL);
    setCarryR(Handfunction.carryR);
    setCarryL(Handfunction.carryL);
    setWashR(Handfunction.washR);
    setWashL(Handfunction.washL);
    setKnifeR(Handfunction.knifeR);
    setKnifeL(Handfunction.knifeL);
    setActivitiesR(Handfunction.activitiesR);
    setActivitiesL(Handfunction.activitiesL);
    setSleepR(Handfunction.sleepR);
    setSleepL(Handfunction.sleepL);
    setUsualR(Handfunction.usualR);
    setUsualL(Handfunction.usualL);
    setWorkR(Handfunction.workR);
    setWorkL(Handfunction.workL);
    setSpendingR(Handfunction.spendingR);
    setSpendingL(Handfunction.spendingL);
    setInstrumentR(Handfunction.instrumentR);
    setInstrumentL(Handfunction.instrumentL);
    setPlayingR(Handfunction.playingR);
    setPlayingL(Handfunction.playingL);
    setSpendR(Handfunction.spendR);
    setSpendL(Handfunction.spendL);
    setSocialR(Handfunction.socialR);
    setSocialL(Handfunction.socialL);
    setLimitR(Handfunction.limitR);
    setLimitL(Handfunction.limitL);
    setSpecifyR(Handfunction.SpecifyR);
    setSpecifyL(Handfunction.SpecifyL);
    setCheckR(Handfunction.checkR);
    setCheckL(Handfunction.checkL);
    setTextR(Handfunction.textR);
    setTextL(Handfunction.textL);
    setSpecifysportR(Handfunction.SpecifysportR);
    setSpecifysportL(Handfunction.SpecifysportL);
    setChecksportR(Handfunction.checksportR);
    setChecksportL(Handfunction.checksportL);
    setTextsportR(Handfunction.textsportR);
    setTextsportL(Handfunction.textsportL);
  }, [Handfunction]);

  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true"?"1":"0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [Handfunction, updateTotal]);

  const handleOverallR = (e) => {
    setOverallR(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "overallR",
        value: e,
      })
    );
  };
  const handleOverallL = (e) => {
    setOverallL(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "overallL",
        value: e,
      })
    );
  };
  const handleOftenR = (e) => {
    setOftenR(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "oftenR",
        value: e,
      })
    );
  };
  const handleOftenL = (e) => {
    setOftenL(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "oftenL",
        value: e,
      })
    );
  };
  const handleLongerR = (e) => {
    setLongerR(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "longerR",
        value: e,
      })
    );
  };
  const handleLongerL = (e) => {
    setLongerL(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "longerL",
        value: e,
      })
    );
  };
  const handleTightR = (e) => {
    setTightR(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "tightR",
        value: e,
      })
    );
  };
  const handleTightL = (e) => {
    setTightL(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "tightL",
        value: e,
      })
    );
  };
  const handleFryingR = (e) => {
    setFryingR(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "fryingR",
        value: e,
      })
    );
  };
  const handleFryingL = (e) => {
    setFryingL(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "fryingL",
        value: e,
      })
    );
  };
  const handleButtonR = (e) => {
    setButtonR(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "buttonR",
        value: e,
      })
    );
  };
  const handleButtonL = (e) => {
    setButtonL(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "buttonL",
        value: e,
      })
    );
  };
  const handleHouseholdR = (e) => {
    setHouseholdR(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "householdR",
        value: e,
      })
    );
  };
  const handleHouseholdL = (e) => {
    setHouseholdL(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "householdL",
        value: e,
      })
    );
  };
  const handleCarryR = (e) => {
    setCarryR(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "carryR",
        value: e,
      })
    );
  };
  const handleCarryL = (e) => {
    setCarryL(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "carryL",
        value: e,
      })
    );
  };
  const handleWashR = (e) => {
    setWashR(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "washR",
        value: e,
      })
    );
  };
  const handleWashL = (e) => {
    setWashL(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "washL",
        value: e,
      })
    );
  };
  const handleKnifeR = (e) => {
    setKnifeR(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "knifeR",
        value: e,
      })
    );
  };
  const handleKnifeL = (e) => {
    setKnifeL(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "knifeL",
        value: e,
      })
    );
  };
  const handleActivitiesR = (e) => {
    setActivitiesR(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "activitiesR",
        value: e,
      })
    );
  };
  const handleActivitiesL = (e) => {
    setActivitiesL(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "activitiesL",
        value: e,
      })
    );
  };
  const handleSleepR = (e) => {
    setSleepR(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "sleepR",
        value: e,
      })
    );
  };
  const handleSleepL = (e) => {
    setSleepL(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "sleepL",
        value: e,
      })
    );
  };
  const handleUsualR = (e) => {
    setUsualR(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "usualR",
        value: e,
      })
    );
  };
  const handleUsualL = (e) => {
    setUsualL(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "usualL",
        value: e,
      })
    );
  };
  const handleWorkR = (e) => {
    setWorkR(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "workR",
        value: e,
      })
    );
  };
  const handleWorkL = (e) => {
    setWorkL(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "workL",
        value: e,
      })
    );
  };
  const handleSpendingR = (e) => {
    setSpendingR(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "spendingR",
        value: e,
      })
    );
  };
  const handleSpendingL = (e) => {
    setSpendingL(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "spendingL",
        value: e,
      })
    );
  };
  const handleInstrumentR = (e) => {
    setInstrumentR(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "instrumentR",
        value: e,
      })
    );
  };
  const handleInstrumentL = (e) => {
    setInstrumentL(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "instrumentL",
        value: e,
      })
    );
  };
  const handlePlayingR = (e) => {
    setPlayingR(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "playingR",
        value: e,
      })
    );
  };
  const handlePlayingL = (e) => {
    setPlayingL(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "playingL",
        value: e,
      })
    );
  };
  const handleSpendR = (e) => {
    setSpendR(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "spendR",
        value: e,
      })
    );
  };
  const handleSpendL = (e) => {
    setSpendL(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "spendL",
        value: e,
      })
    );
  };
  const handleSocialR = (e) => {
    setSocialR(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "socialR",
        value: e,
      })
    );
  };
  const handleSocialL = (e) => {
    setSocialL(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "socialL",
        value: e,
      })
    );
  };
  const handleLimitR = (e) => {
    setLimitR(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "limitR",
        value: e,
      })
    );
  };
  const handleLimitL = (e) => {
    setLimitL(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "limitL",
        value: e,
      })
    );
  };

  const handleSpecifyR = (e) => {
    setSpecifyR(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "SpecifyR",
        value: e,
      })
    );
  };
  const handleSpecifyL = (e) => {
    setSpecifyL(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "SpecifyL",
        value: e,
      })
    );
  };
  const handleCheckR = (e) => {
    setCheckR(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "checkR",
        value: e,
      })
    );

    dispatch(
      updateHandWristfunctionFieldScore({
        key: "usualR",
        value: "",
      })
    );
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "workR",
        value: "",
      })
    );
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "spendingR",
        value: "",
      })
    );
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "usualL",
        value: "",
      })
    );
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "workL",
        value: "",
      })
    );
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "spendingL",
        value: "",
      })
    );
  };

  const handleCheckL = (e) => {
    setCheckL(e);

    dispatch(
      updateHandWristfunctionFieldScore({
        key: "checkL",
        value: e,
      })
    );

    dispatch(
      updateHandWristfunctionFieldScore({
        key: "usualL",
        value: "",
      })
    );
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "workL",
        value: "",
      })
    );
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "spendingL",
        value: "",
      })
    );
  };

  const handleChangeR = (e) => {
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "textR",
        value: e,
      })
    );
    setTextR(e);
  };
  const handleChangeL = (e) => {
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "textL",
        value: e,
      })
    );
    setTextL(e);
  };

  const handleSpecifysportR = (e) => {
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "SpecifysportR",
        value: e,
      })
    );
    setSpecifysportR(e);
  };
  const handleSpecifysportL = (e) => {
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "SpecifysportL",
        value: e,
      })
    );
    setSpecifysportL(e);
  };
  const handleChecksportR = (e) => {
    setChecksportR(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "checksportR",
        value:e,
      })
    );
    if(e){
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "instrumentR",
        value:'',
      })
    );
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "playingR",
        value:'',
      })
    );
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "spendR",
        value:'',
      })
    );

    // dispatch(
    //   updateHandWristfunctionFieldScore({
    //     key: "checksportL",
    //     value:'0',
    //   })
    // );
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "instrumentL",
        value:'',
      })
    );
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "playingL",
        value:'',
      })
    );
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "spendL",
        value:'',
      })
    );
  }
  };

  const handleChecksportL = (e) => {
    setChecksportL(e);
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "checksportL",
        value: e,
      })
    );
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "instrumentL",
        value: e,
      })
    );
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "playingL",
        value: e,
      })
    );
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "spendL",
        value: e,
      })
    );
  };

  const handleChangesportR = (e) => {
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "textsportR",
        value: e,
      })
    );
    setTextsportR(e);
  };
  const handleChangesportL = (e) => {
    dispatch(
      updateHandWristfunctionFieldScore({
        key: "textsportL",
        value: e,
      })
    );
    setTextsportL(e);
  };
  const handleEvoluted = (e) => {
    dispatch(updateHandWristScores(e));
  };
  const continueRedirect = (e) => {
    dispatch(
      updateHandWristfunctionScores([
        {
          overallR: overallR,
          overallL: overallL,
          oftenR: oftenR,
          oftenL: oftenL,
          longerR: longerR,
          longerL: longerL,
          tightR: tightR,
          tightL: tightL,
          fryingR: fryingR,
          fryingL: fryingL,
          buttonR: buttonR,
          buttonL: buttonL,
          householdR: householdR,
          householdL: householdL,
          carryR: carryR,
          carryL: carryL,
          washR: washR,
          washL: washL,
          knifeR: knifeR,
          knifeL: knifeL,
          activitiesR: activitiesR,
          activitiesL: activitiesL,
          sleepR: sleepR,
          sleepL: sleepL,
          usualR: usualR,
          usualL: usualL,
          workR: workR,
          workL: workL,
          spendingR: spendingR,
          spendingL: spendingL,
          instrumentR: instrumentR,
          instrumentL: instrumentL,
          playingR: playingR,
          playingL: playingL,
          spendR: spendR,
          spendL: spendL,
          socialR: socialR,
          socialL: socialL,
          limitR: limitR,
          limitL: limitL,
        },
      ])
    );

    if (syndrome === "2") {
      navigate("/hand-wrist-bctq/" + rId);
    } else if (disease === "2") {
      navigate("/hand-wrist-uram/" + rId);
    } else navigate("/hand-wrist-qol/" + rId);
  };

  let akpt = searchParams.get("akpt");
  const [msg, setMsg] = useState("");
  const [msgType, setmsgType] = useState("");
  
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update);
    setmsgType("success");
    setmsgType("success");
    setTimeout(() => {
    let view_circular=  sessionStorage.getItem("view_circular")
     // window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" + akpt
    //  window.location.href = process.env.REACT_APP_DOCTOR_URL +`view-management-plan/${akpt}/${window.btoa(
    //    rId
    //  )}/?org=${window.btoa(sessionStorage.getItem("orgid")||'')}&formType=${
    //    window.btoa('23') || ""
    //  }&view_circular=${view_circular==='false'?'false':'true'}`
    window.location.href = process.env.REACT_APP_DOCTOR_URL +`patient-details/${akpt}?formid=23`
     setMsg('')
     setmsgType('success')
   }, 2000);
  };
  const handleNext=()=>{
    if (syndrome === "2") {
      navigate("/hand-wrist-bctq/" + rId+`?d=true&akpt=${akpt}`);
    } else if (disease === "2") {
      navigate("/hand-wrist-uram/" + rId+`?d=true&akpt=${akpt}`);
    } else navigate("/hand-wrist-qol/" + rId+`?d=true&akpt=${akpt}`);
    // navigate(`/hand-wrist-qol/${rId}?d=true&akpt=${akpt}`);

  }
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="pain_screen mb_100">
          <div class="d-flex justify-content-end">
            <NameCustom />
          </div>
          <div className="panel">
            <div className="panel-body p_25">
              {doctorEnable === "true" && <HandWristTab rId={rId} />}
              <CustomToaster msg={msg} msgType={msgType} />
              <h4 className="ph_title f_40">
                {translation.patient_hand_function}
              </h4>
              <strong>{translation.patient_hand_function_instruction1} </strong>{" "}
              <br />
              <strong>{translation.patient_hand_function_instruction2} </strong>
              <div className="row mt-4">
                {evaluated === "1" || evaluated === "3" ? (
                  <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                    <HandWristScoreRangeSlider
                      label={
                        translation.patient_hand_function_q1 +
                        RigthTrans(evaluated, translation)
                      }
                      styleClass=""
                      value={overallR}
                      onChange={handleOverallR}
                      sliderData={[
                        {
                          score: "0",
                          label: translation.patient_hand_function_q1_a1,
                        },
                        {
                          score: "1",
                          label: translation.patient_hand_function_q1_a2,
                        },
                        {
                          score: "2",
                          label: translation.patient_hand_function_q1_a3,
                        },
                        {
                          score: "3",
                          label: translation.patient_hand_function_q1_a4,
                        },
                        {
                          score: "4",
                          label: translation.patient_hand_function_q1_a5,
                        },
                      ]}
                    />
                  </div>
                ) : (
                  ""
                )}
                {evaluated === "2" || evaluated === "3" ? (
                  <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                    <HandWristScoreRangeSlider
                      label={
                        translation.patient_hand_function_q1 +
                        LeftTrans(evaluated, translation)
                      }
                      styleClass=""
                      value={overallL}
                      onChange={handleOverallL}
                      sliderData={[
                        {
                          score: "0",
                          label: translation.patient_hand_function_q1_a1,
                        },
                        {
                          score: "1",
                          label: translation.patient_hand_function_q1_a2,
                        },
                        {
                          score: "2",
                          label: translation.patient_hand_function_q1_a3,
                        },
                        {
                          score: "3",
                          label: translation.patient_hand_function_q1_a4,
                        },
                        {
                          score: "4",
                          label: translation.patient_hand_function_q1_a5,
                        },
                      ]}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="row">
                {evaluated === "1" || evaluated === "3" ? (
                  <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                    <HandWristScoreRangeSlider
                      label={
                        translation.patient_hand_function_q2 +
                        RigthTrans(evaluated, translation)
                      }
                      styleClass=""
                      value={oftenR}
                      onChange={handleOftenR}
                      sliderData={[
                        {
                          score: "0",
                          label: translation.patient_hand_function_q2_a1,
                        },
                        {
                          score: "1",
                          label: translation.patient_hand_function_q2_a2,
                        },
                        {
                          score: "2",
                          label: translation.patient_hand_function_q2_a3,
                        },
                        {
                          score: "3",
                          label: translation.patient_hand_function_q2_a4,
                        },
                        {
                          score: "4",
                          label: translation.patient_hand_function_q2_a5,
                        },
                      ]}
                    />
                  </div>
                ) : (
                  ""
                )}
                {evaluated === "2" || evaluated === "3" ? (
                  <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                    <HandWristScoreRangeSlider
                      label={
                        translation.patient_hand_function_q2 +
                        LeftTrans(evaluated, translation)
                      }
                      styleClass=""
                      value={oftenL}
                      onChange={handleOftenL}
                      sliderData={[
                        {
                          score: "0",
                          label: translation.patient_hand_function_q2_a1,
                        },
                        {
                          score: "1",
                          label: translation.patient_hand_function_q2_a2,
                        },
                        {
                          score: "2",
                          label: translation.patient_hand_function_q2_a3,
                        },
                        {
                          score: "3",
                          label: translation.patient_hand_function_q2_a4,
                        },
                        {
                          score: "4",
                          label: translation.patient_hand_function_q2_a5,
                        },
                      ]}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="row">
                {evaluated === "1" || evaluated === "3" ? (
                  <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                    <HandWristScoreRangeSlider
                      label={
                        translation.patient_hand_function_q3 +
                        RigthTrans(evaluated, translation)
                      }
                      styleClass=""
                      value={longerR}
                      onChange={handleLongerR}
                      sliderData={[
                        {
                          score: "0",
                          label: translation.patient_hand_function_q3_a1,
                        },
                        {
                          score: "1",
                          label: translation.patient_hand_function_q3_a2,
                        },
                        {
                          score: "2",
                          label: translation.patient_hand_function_q3_a3,
                        },
                        {
                          score: "3",
                          label: translation.patient_hand_function_q3_a4,
                        },
                        {
                          score: "4",
                          label: translation.patient_hand_function_q3_a5,
                        },
                      ]}
                    />
                  </div>
                ) : (
                  ""
                )}
                {evaluated === "2" || evaluated === "3" ? (
                  <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                    <HandWristScoreRangeSlider
                      label={
                        translation.patient_hand_function_q3 +
                        LeftTrans(evaluated, translation)
                      }
                      styleClass=""
                      value={longerL}
                      onChange={handleLongerL}
                      sliderData={[
                        {
                          score: "0",
                          label: translation.patient_hand_function_q3_a1,
                        },
                        {
                          score: "1",
                          label: translation.patient_hand_function_q3_a2,
                        },
                        {
                          score: "2",
                          label: translation.patient_hand_function_q3_a3,
                        },
                        {
                          score: "3",
                          label: translation.patient_hand_function_q3_a4,
                        },
                        {
                          score: "4",
                          label: translation.patient_hand_function_q3_a5,
                        },
                      ]}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="function_formtop">
                <div className="row">
                  <div className="col-md-10 col-sm-6 mb_10">
                    <strong className="d-inline-block mb_15">
                      {translation.patient_hand_function_instruction}
                    </strong>
                    <br></br>
                    <span dir={lg === "ara" ? "rtl" : " "}>
                      (<strong>0</strong> ={" "}
                      {translation.patient_hand_function_instruction_0},{" "}
                      <strong>1</strong> ={" "}
                      {translation.patient_hand_function_instruction_1},{" "}
                      <strong>2</strong> ={" "}
                      {translation.patient_hand_function_instruction_2},{" "}
                      <strong>3</strong> ={" "}
                      {translation.patient_hand_function_instruction_3},{" "}
                      <strong>4</strong> ={" "}
                      {translation.patient_hand_function_instruction_4})
                    </span>
                  </div>
                </div>
                <div className="row">
                  {evaluated === "1" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <HandWristScoreRangeSlider
                        label={
                          translation.patient_hand_function_q4 +
                          RigthTrans(evaluated, translation)
                        }
                        classes=""
                        value={tightR}
                        onChange={handleTightR}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_function_q4_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_function_q4_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_function_q4_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_function_q4_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_function_q4_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evaluated === "2" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <HandWristScoreRangeSlider
                        label={
                          translation.patient_hand_function_q4 +
                          LeftTrans(evaluated, translation)
                        }
                        classes=""
                        value={tightL}
                        onChange={handleTightL}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_function_q4_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_function_q4_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_function_q4_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_function_q4_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_function_q4_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evaluated === "1" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <HandWristScoreRangeSlider
                        label={
                          translation.patient_hand_function_q5 +
                          RigthTrans(evaluated, translation)
                        }
                        classes=""
                        value={fryingR}
                        onChange={handleFryingR}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_function_q5_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_function_q5_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_function_q5_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_function_q5_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_function_q5_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evaluated === "2" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <HandWristScoreRangeSlider
                        label={
                          translation.patient_hand_function_q5 +
                          LeftTrans(evaluated, translation)
                        }
                        classes=""
                        value={fryingL}
                        onChange={handleFryingL}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_function_q5_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_function_q5_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_function_q5_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_function_q5_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_function_q5_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evaluated === "1" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <HandWristScoreRangeSlider
                        label={
                          translation.patient_hand_function_q6 +
                          RigthTrans(evaluated, translation)
                        }
                        classes=""
                        value={buttonR}
                        onChange={handleButtonR}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_function_q6_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_function_q6_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_function_q6_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_function_q6_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_function_q6_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evaluated === "2" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <HandWristScoreRangeSlider
                        label={
                          translation.patient_hand_function_q6 +
                          LeftTrans(evaluated, translation)
                        }
                        classes=""
                        value={buttonL}
                        onChange={handleButtonL}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_function_q6_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_function_q6_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_function_q6_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_function_q6_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_function_q6_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evaluated === "1" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <HandWristScoreRangeSlider
                        label={
                          translation.patient_hand_function_q7 +
                          RigthTrans(evaluated, translation)
                        }
                        classes=""
                        value={householdR}
                        onChange={handleHouseholdR}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_function_q7_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_function_q7_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_function_q7_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_function_q7_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_function_q7_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evaluated === "2" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <HandWristScoreRangeSlider
                        label={
                          translation.patient_hand_function_q7 +
                          LeftTrans(evaluated, translation)
                        }
                        classes=""
                        value={householdL}
                        onChange={handleHouseholdL}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_function_q7_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_function_q7_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_function_q7_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_function_q7_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_function_q7_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evaluated === "1" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <HandWristScoreRangeSlider
                        label={
                          translation.patient_hand_function_q8 +
                          RigthTrans(evaluated, translation)
                        }
                        classes=""
                        value={carryR}
                        onChange={handleCarryR}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_function_q8_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_function_q8_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_function_q8_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_function_q8_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_function_q8_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evaluated === "2" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <HandWristScoreRangeSlider
                        label={
                          translation.patient_hand_function_q8 +
                          LeftTrans(evaluated, translation)
                        }
                        classes=""
                        value={carryL}
                        onChange={handleCarryL}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_function_q8_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_function_q8_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_function_q8_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_function_q8_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_function_q8_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evaluated === "1" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <HandWristScoreRangeSlider
                        label={
                          translation.patient_hand_function_q9 +
                          RigthTrans(evaluated, translation)
                        }
                        classes=""
                        value={washR}
                        onChange={handleWashR}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_function_q9_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_function_q9_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_function_q9_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_function_q9_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_function_q9_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evaluated === "2" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <HandWristScoreRangeSlider
                        label={
                          translation.patient_hand_function_q9 +
                          LeftTrans(evaluated, translation)
                        }
                        classes=""
                        value={washL}
                        onChange={handleWashL}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_function_q9_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_function_q9_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_function_q9_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_function_q9_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_function_q9_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evaluated === "1" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <HandWristScoreRangeSlider
                        label={
                          translation.patient_hand_function_q10 +
                          RigthTrans(evaluated, translation)
                        }
                        classes=""
                        value={knifeR}
                        onChange={handleKnifeR}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_function_q10_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_function_q10_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_function_q10_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_function_q10_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_function_q10_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evaluated === "2" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <HandWristScoreRangeSlider
                        label={
                          translation.patient_hand_function_q10 +
                          LeftTrans(evaluated, translation)
                        }
                        classes=""
                        value={knifeL}
                        onChange={handleKnifeL}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_function_q10_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_function_q10_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_function_q10_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_function_q10_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_function_q10_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evaluated === "1" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <HandWristScoreRangeSlider
                        label={
                          translation.patient_hand_function_q11 +
                          RigthTrans(evaluated, translation)
                        }
                        classes=""
                        value={activitiesR}
                        onChange={handleActivitiesR}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_function_q11_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_function_q11_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_function_q11_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_function_q11_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_function_q11_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evaluated === "2" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <HandWristScoreRangeSlider
                        label={
                          translation.patient_hand_function_q11 +
                          LeftTrans(evaluated, translation)
                        }
                        classes=""
                        value={activitiesL}
                        onChange={handleActivitiesL}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_function_q11_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_function_q11_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_function_q11_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_function_q11_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_function_q11_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evaluated === "1" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <HandWristScoreRangeSlider
                        label={
                          translation.patient_hand_function_q12 +
                          RigthTrans(evaluated, translation)
                        }
                        classes=""
                        value={sleepR}
                        onChange={handleSleepR}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_function_q12_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_function_q12_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_function_q12_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_function_q12_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_function_q12_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evaluated === "2" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <HandWristScoreRangeSlider
                        label={
                          translation.patient_hand_function_q12 +
                          LeftTrans(evaluated, translation)
                        }
                        classes=""
                        value={sleepL}
                        onChange={handleSleepL}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_function_q12_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_function_q12_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_function_q12_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_function_q12_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_function_q12_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="row">
                  {evaluated === "1" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      {/* <strong className="d-inline-block mb_15">
                                                {evaluated === "3" ? translation.Doing_your_usual_work_R : translation.Doing_your_usual_work}
                                            </strong> */}
                      <HandWristScoreRangeSlider
                        label={
                          translation.patient_hand_function_q13 +
                          RigthTrans(evaluated, translation)
                        }
                        classes=""
                        value={SpecifyR}
                        onChange={handleSpecifyR}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_function_q13_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_function_q13_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_function_q13_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_function_q13_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_function_q13_a5,
                          },
                        ]}
                      />
                      {/* <InputField
                                                label={translation.patient_hand_function_q14}
                                                onChange={handleChangeR}
                                                value={textR}
                                                placeholder={translation.patient_hand_function_q14_placeholder}
                                            /> */}
                    </div>
                  ) : (
                    ""
                  )}
                 
                  {evaluated === "2" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      {/* <strong className="d-inline-block mb_15">
                                                {evaluated === "3" ? translation.Doing_your_usual_work_L : translation.Doing_your_usual_work}
                                            </strong> */}
                      <HandWristScoreRangeSlider
                        label={
                          translation.patient_hand_function_q13 +
                          LeftTrans(evaluated, translation)
                        }
                        classes=""
                        value={SpecifyL}
                        onChange={handleSpecifyL}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_function_q13_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_function_q13_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_function_q13_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_function_q13_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_function_q13_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                </div>
                <InputField
                    label={translation.patient_hand_function_q14}
                    onChange={handleChangeL}
                    value={textL}
                    styleClass="w-50"
                    placeholder={
                      translation.patient_hand_function_q14_placeholder
                    }
                  />
                <div className="row">
                  {/* {evaluated === "1" || evaluated === "3" ? ( */}
                  <div className="col-md-6 col-sm-6 mb_10">
                    <Checkbox
                      label={translation.patient_hand_function_do_dot_work}
                      selected={checkR}
                      value={checkR}
                      onChange={handleCheckR}
                    />
                  </div>
                  {/* ) : null} */}
                  {/* {evaluated === "2" || evaluated === "3" ? (
                                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                                            <Checkbox
                                                label={evaluated === "3" ? translation.Do_not_work_L : translation.Do_not_work}
                                                value={checkL}
                                                selected={checkL}
                                                onChange={handleCheckL}
                                            />
                                        </div>
                                    ) : null} */}
                </div>

                <div className="row">
                  {evaluated === "1" || (evaluated === "3" && !checkR) ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <HandWristScoreRangeSlider
                        label={
                          translation.patient_hand_function_q15 +
                          RigthTrans(evaluated, translation)
                        }
                        classes=""
                        value={usualR}
                        onChange={handleUsualR}
                        onClick={handleUsualR}
                        view={checkR === true ? true : false}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_function_q15_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_function_q15_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_function_q15_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_function_q15_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_function_q15_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evaluated === "2" || (evaluated === "3" && !checkR) ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <HandWristScoreRangeSlider
                        label={
                          translation.patient_hand_function_q15 +
                          LeftTrans(evaluated, translation)
                        }
                        classes=""
                        value={usualL}
                        onChange={handleUsualL}
                        onClick={handleUsualL}
                        view={checkL === true ? true : false}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_function_q15_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_function_q15_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_function_q15_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_function_q15_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_function_q15_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="row">
                  {evaluated === "1" || (evaluated === "3" && !checkR) ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <HandWristScoreRangeSlider
                        label={
                          translation.patient_hand_function_q16 +
                          RigthTrans(evaluated, translation)
                        }
                        classes=""
                        value={workR}
                        onChange={handleWorkR}
                        onClick={handleWorkR}
                        view={checkR === true ? true : false}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_function_q16_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_function_q16_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_function_q16_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_function_q16_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_function_q16_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evaluated === "2" || (evaluated === "3" && !checkR) ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <HandWristScoreRangeSlider
                        label={
                          translation.patient_hand_function_q16 +
                          LeftTrans(evaluated, translation)
                        }
                        classes=""
                        value={workL}
                        onChange={handleWorkL}
                        onClick={handleWorkL}
                        view={checkL === true ? true : false}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_function_q16_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_function_q16_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_function_q16_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_function_q16_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_function_q16_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="row">
                  {evaluated === "1" || (evaluated === "3" && !checkR) ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <HandWristScoreRangeSlider
                        label={
                          translation.patient_hand_function_q17 +
                          RigthTrans(evaluated, translation)
                        }
                        classes=""
                        value={spendingR}
                        onChange={handleSpendingR}
                        onClick={handleSpendingR}
                        view={checkR === true ? true : false}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_function_q17_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_function_q17_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_function_q17_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_function_q17_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_function_q17_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evaluated === "2" || (evaluated === "3" && !checkR) ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <HandWristScoreRangeSlider
                        label={
                          translation.patient_hand_function_q17 +
                          LeftTrans(evaluated, translation)
                        }
                        classes=""
                        value={spendingL}
                        onChange={handleSpendingL}
                        onClick={handleSpendingL}
                        view={checkL === true ? true : false}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_function_q17_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_function_q17_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_function_q17_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_function_q17_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_function_q17_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="row">
                  {evaluated === "1" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      {/* <strong className="d-inline-block mb_15">
                                                {evaluated === "3" ? translation.Playing_your_musical_instrument_hand_pain_R : translation.Playing_your_musical_instrument_hand_pain}
                                            </strong> */}
                      <HandWristScoreRangeSlider
                        label={
                          translation.patient_hand_function_q18 +
                          RigthTrans(evaluated, translation)
                        }
                        classes=""
                        value={SpecifysportR}
                        onChange={handleSpecifysportR}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_function_q18_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_function_q18_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_function_q18_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_function_q18_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_function_q18_a5,
                          },
                        ]}
                      />
                      {/* <InputField
                                                onChange={handleChangesportR}
                                                value={textsportR}
                                                label={translation.patient_hand_function_q19}
                                                placeholder={translation.patient_hand_function_q19_placeholder}

                                            /> */}
                    </div>
                  ) : (
                    ""
                  )}
                
                  {evaluated === "2" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      {/* <strong className="d-inline-block mb_15">
                                                {evaluated === "3" ? translation.Playing_your_musical_instrument_hand_pain_L : translation.Playing_your_musical_instrument_hand_pain}
                                            </strong> */}
                      <HandWristScoreRangeSlider
                        label={
                          translation.patient_hand_function_q18 +
                          LeftTrans(evaluated, translation)
                        }
                        classes=""
                        value={SpecifysportL}
                        onChange={handleSpecifysportL}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_function_q18_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_function_q18_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_function_q18_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_function_q18_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_function_q18_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <InputField
                    label={translation.patient_hand_function_q19}
                    onChange={handleChangesportL}
                    value={textsportL}
                    styleClass="w-50"
                    placeholder={
                      translation.patient_hand_function_q19_placeholder
                    }
                  />
                <div className="row">
                  {/* {evaluated === "1" || evaluated === "3" ? ( */}
                  <div className="col-md-6 col-sm-6 mb_10">
                    <Checkbox
                      label={translation.patient_hand_function_donot_play}
                      value={checksportR}
                      selected={checksportR}
                      onChange={handleChecksportR}
                    />
                  </div>
                  {/* ) : null} */}
                  {/* {evaluated === "2" || evaluated === "3" ? (
                                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                                            <Checkbox
                                                label={evaluated === "3" ? translation.Do_not_work_p_L : translation.Do_not_work_p}
                                                value={checksportL}
                                                selected={checksportL}
                                                onChange={handleChecksportL}
                                            />
                                        </div>
                                    ) : null} */}
                </div>

                <div className="row">
                  {evaluated === "1" || (evaluated === "3" && !checksportR) ? (
                    <>
                      <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                        <HandWristScoreRangeSlider
                          label={
                            translation.patient_hand_function_20 +
                            RigthTrans(evaluated, translation)
                          }
                          classes=""
                          value={instrumentR}
                          onChange={handleInstrumentR}
                          onClick={handleInstrumentR}
                          view={checksportR === true ? true : false}
                          sliderData={[
                            {
                              score: "0",
                              label: translation.patient_hand_function_q20_a1,
                            },
                            {
                              score: "1",
                              label: translation.patient_hand_function_q20_a2,
                            },
                            {
                              score: "2",
                              label: translation.patient_hand_function_q20_a3,
                            },
                            {
                              score: "3",
                              label: translation.patient_hand_function_q20_a4,
                            },
                            {
                              score: "4",
                              label: translation.patient_hand_function_q20_a5,
                            },
                          ]}
                        />
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {evaluated === "2" || (evaluated === "3" && !checksportR) ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <HandWristScoreRangeSlider
                        label={
                          translation.patient_hand_function_20 +
                          LeftTrans(evaluated, translation)
                        }
                        classes=""
                        value={instrumentL}
                        onChange={handleInstrumentL}
                        onClick={handleInstrumentL}
                        view={checksportR === true ? true : false}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_function_q20_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_function_q20_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_function_q20_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_function_q20_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_function_q20_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="row">
                  {evaluated === "1" || (evaluated === "3" && !checksportR) ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <HandWristScoreRangeSlider
                        label={
                          translation.patient_hand_function_q21 +
                          RigthTrans(evaluated, translation)
                        }
                        classes=""
                        value={playingR}
                        onChange={handlePlayingR}
                        onClick={handlePlayingR}
                        view={checksportR === true ? true : false}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_function_q21_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_function_q21_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_function_q21_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_function_q21_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_function_q21_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evaluated === "2" || (evaluated === "3" && !checksportR) ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <HandWristScoreRangeSlider
                        label={
                          translation.patient_hand_function_q21 +
                          LeftTrans(evaluated, translation)
                        }
                        classes=""
                        value={playingL}
                        onChange={handlePlayingL}
                        onClick={handlePlayingL}
                        view={checksportR === true ? true : false}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_function_q21_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_function_q21_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_function_q21_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_function_q21_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_function_q21_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="row">
                  {evaluated === "1" || (evaluated === "3" && !checksportR) ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <HandWristScoreRangeSlider
                        label={
                          translation.patient_hand_function_q22 +
                          RigthTrans(evaluated, translation)
                        }
                        classes=""
                        value={spendR}
                        onChange={handleSpendR}
                        onClick={handleSpendR}
                        view={checksportR === true ? true : false}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_function_q22_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_function_q22_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_function_q22_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_function_q22_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_function_q22_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evaluated === "2" || (evaluated === "3" && !checksportR) ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <HandWristScoreRangeSlider
                        label={
                          translation.patient_hand_function_q22 +
                          LeftTrans(evaluated, translation)
                        }
                        classes=""
                        value={spendL}
                        onChange={handleSpendL}
                        onClick={handleSpendL}
                        view={checksportR === true ? true : false}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_function_q22_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_function_q22_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_function_q22_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_function_q22_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_function_q22_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row">
                {evaluated === "1" || evaluated === "3" ? (
                  <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                    <HandWristScoreRangeSlider
                      label={
                        translation.patient_hand_function_q23 +
                        RigthTrans(evaluated, translation)
                      }
                      styleClass=""
                      value={socialR}
                      onChange={handleSocialR}
                      sliderData={[
                        {
                          score: "0",
                          label: translation.patient_hand_function_q23_a1,
                        },
                        {
                          score: "1",
                          label: translation.patient_hand_function_q23_a2,
                        },
                        {
                          score: "2",
                          label: translation.patient_hand_function_q23_a3,
                        },
                        {
                          score: "3",
                          label: translation.patient_hand_function_q23_a4,
                        },
                        {
                          score: "4",
                          label: translation.patient_hand_function_q23_a5,
                        },
                      ]}
                    />
                  </div>
                ) : (
                  ""
                )}
                {evaluated === "2" || evaluated === "3" ? (
                  <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                    <HandWristScoreRangeSlider
                      label={
                        translation.patient_hand_function_q23 +
                        LeftTrans(evaluated, translation)
                      }
                      styleClass=""
                      value={socialL}
                      onChange={handleSocialL}
                      sliderData={[
                        {
                          score: "0",
                          label: translation.patient_hand_function_q23_a1,
                        },
                        {
                          score: "1",
                          label: translation.patient_hand_function_q23_a2,
                        },
                        {
                          score: "2",
                          label: translation.patient_hand_function_q23_a3,
                        },
                        {
                          score: "3",
                          label: translation.patient_hand_function_q23_a4,
                        },
                        {
                          score: "4",
                          label: translation.patient_hand_function_q23_a5,
                        },
                      ]}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="row">
                {evaluated === "1" || evaluated === "3" ? (
                  <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                    <HandWristScoreRangeSlider
                      label={
                        translation.patient_hand_function_q24 +
                        RigthTrans(evaluated, translation)
                      }
                      styleClass=""
                      value={limitR}
                      onChange={handleLimitR}
                      sliderData={[
                        {
                          score: "0",
                          label: translation.patient_hand_function_q24_a1,
                        },
                        {
                          score: "1",
                          label: translation.patient_hand_function_q24_a2,
                        },
                        {
                          score: "2",
                          label: translation.patient_hand_function_q24_a3,
                        },
                        {
                          score: "3",
                          label: translation.patient_hand_function_q24_a4,
                        },
                        {
                          score: "4",
                          label: translation.patient_hand_function_q24_a5,
                        },
                      ]}
                    />
                  </div>
                ) : (
                  ""
                )}
                {evaluated === "2" || evaluated === "3" ? (
                  <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                    <HandWristScoreRangeSlider
                      label={
                        translation.patient_hand_function_q24 +
                        LeftTrans(evaluated, translation)
                      }
                      styleClass=""
                      value={limitL}
                      onChange={handleLimitL}
                      sliderData={[
                        {
                          score: "0",
                          label: translation.patient_hand_function_q24_a1,
                        },
                        {
                          score: "1",
                          label: translation.patient_hand_function_q24_a2,
                        },
                        {
                          score: "2",
                          label: translation.patient_hand_function_q24_a3,
                        },
                        {
                          score: "3",
                          label: translation.patient_hand_function_q24_a4,
                        },
                        {
                          score: "4",
                          label: translation.patient_hand_function_q24_a5,
                        },
                      ]}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              {doctorEnable === "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6"></div>
                  <div className="col-md-6 col-sm-6 text-end">
                  <button
                      className="next_btn btn_fill me-3"
                      onClick={() => handleNext()}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                    <button
                      className="next_btn btn_fill"
                      onClick={() => handleSubmit()}
                    >
                      Submit
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                  </div>
                </div>
              )}
              {doctorEnable !== "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">
                    <Link
                      to={"/hand-wrist-symptoms/" + rId}
                      className="assm_back_btn"
                    >
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default HandWristfunction;
