import React, { useState, useEffect } from "react";
import ViewPast from "./ViewPast";

import ViewHistory from "./ViewHistory";
import ViewSocial from "./ViewSocial";
import useAxios from "../../axiosinstance";
import { useParams, useNavigate } from "react-router-dom";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import editIcon from "../../images/edit-icon.svg";
import Button from "../custom/button/Button";
import { useSearchParams } from "react-router-dom";

export default function ViewTreatmentForm() {
  const axiosInstance = useAxios();
  const navigate = useNavigate();
  // const [initialAssesment, setInitialAssesment] = useState(0)
  const [isLoading, setIsLoading] = useState(true);
  const [AllData, setAllData] = useState("");
  const [profileData, setProfileData] = useState({});

  const [editShow, seteditShow] = useState(false);
  const [viewDoctor, setViewDoctor] = useState("0");
  const [viewPatient, setViewPatient] = useState("0");
  const [doctorFeed, setDoctorScore] = useState({});

  const [historyData, setHistorydata] = useState({});
  const [socialData, setSocialdata] = useState({});
  const [pastData, setPastdata] = useState({});
  const [minDate, setMinDate] = useState("")

  const { akpt } = useParams();

  let ptId = window.atob(akpt);
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("doctor_id");
  let isdoctorForm = searchParams.get("is_doctor_form");
  useEffect(() => {
    if (ptId) {
      localStorage.setItem("medical_pt", ptId);
    }
  }, [ptId]);



  const save = () => {
    axiosInstance.current
      .get(
        `extapp/doctors/getMedicalSummaryList?ak_id=${ptId}&doctor_id=${
          doctorEnable ? doctorEnable : ""
        }&is_patient=${doctorEnable ? 0 : 1}`
      )
      .then((res) => {
        console.log(res, "res");
        setHistorydata(res.data.data.History[0]);
        setSocialdata(res.data.data.Social[0]);
        setPastdata(res.data.data.Past[0]);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response !== undefined && err.response.status === 401) {
          navigate("/un-authorized-access");
        }
      });
  };
  useEffect(() => {
    save();
  }, [ptId]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  // console.log(Response.historyData.AllData,"history data")

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className={`text-end`}>
            <Button
              onClick={() =>
                navigate(
                  `/medical-history/${window.btoa(ptId)}?doctor=${
                    doctorEnable ? doctorEnable : ""
                  }&is_doctor_form=${isdoctorForm}`
                )
              }
              value="Update Form"
              buttonStyle="btn_fill ml_15 "
            />
          </div>

          <ViewHistory historyData={historyData} />
          <ViewPast pastData={pastData} />
          <ViewSocial socialData={socialData} />
        </>
      )}
    </>
  );
}
