import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import "./RangeSlider.css";
import happyFace from "../../../images/happy-face.png";
import sadFace from "../../../images/sad-face.png";
import useTranslation from "../../customHooks/translations";

const RangeSlider = ({
  id,
  min,
  max,
  step,
  onChange,
  onMouseUp,
  name,
  type,
  label,
  leftTitle,
  rightTitle,
  value,
  formGroupClass,
  colorcode,
  hipSlider,
  kneeSlider,
  kneeSlider_severe,
  kneeSlider_severe_none,
  PediakneeSlider,
  view,
  span1,
  sliderData,
  styleClass,
  color,
  elbowsymptom,
}) => {
  const [rangePercent, setRangePercent] = useState(
    (100 * (value > 0 ? value - min : 0)) / (max - min)
  );
  const sliderRef = useRef(null);
  // console.log("--range percent--", rangePercent)
  const fill = `#40E0D0 0%, #FFE600 ${
    rangePercent / 2
  }%, #FF0000 ${rangePercent}%`;
  const fill1 = `#FF0000 0%, #FFE600 ${
    rangePercent / 2
  }%, #40E0D0 ${rangePercent}%`;
  const background = "rgb(215, 220, 223)";
  const bg = `linear-gradient(90deg, ${
    colorcode === "1" ? fill1 : fill
  } ${rangePercent}%, ${background} ${rangePercent}%)`;
  let tpPosition = `${rangePercent}%`;
  let rtPosition = `${100 - rangePercent}%`;
  // console.log("ptuy", tpPosition);
  const handleChange = (event) => {
    const { value } = event.target;
    const rangePercentValue =
      (100 * (value > 0 ? value - min : 0)) / (max - min);
    setRangePercent(rangePercentValue);
    onChange(value);
  };
  useEffect(() => {
    if (String(value) === "0") {
      setRangePercent(0);
      tpPosition = "0%";
      rtPosition = `100%`;
      sliderRef.current.value = 0;
    }
  }, [value]);
  const translation = useTranslation();
  let lang = sessionStorage.getItem("language");
  return (
    <div className={`form-group ${formGroupClass} mt-4`}>
      {label && (
        <label
          htmlFor=""
          style={{ color: color, marginLeft: elbowsymptom ? "18px" : "", marginBottom: "20px" }}
        >
          {label}
        </label>
      )}{" "}
      <label className="mb-4"> {span1} </label>
      <div
        className={
          colorcode === "1"
            ? `range_slider_box1  ${
                elbowsymptom ? "mt-0" : "rangeslider"
              } ${styleClass}`
            : `range_slider_box  ${styleClass}`
        }
      >
        {colorcode === "1" ? (
          <>
            {type === "image" && (
              <span className="sad_face">
                <img src={sadFace} alt="icon" />
              </span>
            )}
          </>
        ) : (
          <>
            {type === "image" && (
              <span className="happy_face">
                <img src={happyFace} alt="icon" />
              </span>
            )}
          </>
        )}
        {type === "text" && (
          <span className={lang === "ara" ? "stable_txt arb " : "stable_txt"}>
            {lang === "ara" ? rightTitle : leftTitle}
          </span>
        )}
        {type === "both" && (
          <>
            <span className="happy_face">
              <img src={happyFace} alt="icon" />
            </span>
            <span
              className={lang === "ara" ? "stable_txt arb " : "stable_txt mt-3"}
            >
              {lang === "ara" ? rightTitle : leftTitle}
            </span>
          </>
        )}
        {type === "both1" && (
          <>
            <span className="happy_face">
              <img src={sadFace} alt="icon" />
            </span>
            <span
              className={
                lang === "ara" ? "stable_txt arb " : "stable_txt mt-3 "
              }
            >
              {lang === "ara" ? rightTitle : leftTitle}
            </span>
          </>
        )}

        <div className="range-slider">
          <div className="tooltip_main">
            {lang === "ara" ? (
              <span
                className="range_tootip arb"
                style={{ right: `${tpPosition}` }}
              >
                {value}
              </span>
            ) : (
              <span className="range_tootip" style={{ left: `${tpPosition}` }}>
                {value}
              </span>
            )}
          </div>
          <span
            className={
              lang === "ara"
                ? "slider_color_inverse arb"
                : "slider_color_inverse"
            }
            style={{ width: `${rtPosition}` }}
          ></span>
          {colorcode === "1" ? (
            <span
              className={
                lang === "ara"
                  ? "slider_color_front arb2"
                  : "slider_color_front"
              }
            ></span>
          ) : (
            <span
              className={
                lang === "ara" ? "slider_color_front arb" : "slider_color_front"
              }
            ></span>
          )}
          <input
            type="range"
            id={id}
            min={min}
            max={max}
            step={step}
            disabled={view}
            // value={value} // don't set value from state
            defaultValue={value} // but instead pass state value as default value
            onChange={handleChange} // don't set state on all change as react will re-render
            // onMouseUp={handleChange} // only set state when handle is released
            className={`range-slider__range`}
            ref={sliderRef}
            // style={{ background: `${bg}` }}
          />
          {hipSlider && (
            <div className="tooltip_main">
              {lang === "ara" ? (
                <span
                  className="range_tootip range_tootip_knee"
                  style={{ right: `${tpPosition}` }}
                >
                  {sliderData}
                </span>
              ) : (
                <span
                  className="range_tootip range_tootip_knee"
                  style={{ left: `${tpPosition}` }}
                >
                  {sliderData}
                </span>
              )}
            </div>
          )}
          {kneeSlider && (
            <div className="tooltip_main">
              {lang === "ara" ? (
                <span
                  className="range_tootip range_tootip_knee"
                  style={{ right: `${tpPosition}` }}
                >
                  {sliderData}
                </span>
              ) : (
                <span
                  className="range_tootip range_tootip_knee"
                  style={{ left: `${tpPosition}` }}
                >
                  {sliderData}
                </span>
              )}
            </div>
          )}
          {kneeSlider_severe && (
            <div className="tooltip_main">
              {lang === "ara" ? (
                <span
                  className="range_tootip range_tootip_knee"
                  style={{ right: `${tpPosition}` }}
                >
                  {sliderData}
                </span>
              ) : (
                <span
                  className="range_tootip range_tootip_knee"
                  style={{ left: `${tpPosition}` }}
                >
                  {sliderData}
                </span>
              )}
            </div>
          )}
          {kneeSlider_severe_none && (
            <div className="tooltip_main">
              {lang === "ara" ? (
                <span
                  className="range_tootip range_tootip_knee"
                  style={{ right: `${tpPosition}` }}
                >
                  {sliderData}
                </span>
              ) : (
                <span
                  className="range_tootip range_tootip_knee"
                  style={{ left: `${tpPosition}` }}
                >
                  {sliderData}
                </span>
              )}
            </div>
          )}
          {PediakneeSlider && (
            <div className="tooltip_main">
              {lang === "ara" ? (
                <span
                  className="range_tootip range_tootip_knee"
                  style={{ right: `${tpPosition}` }}
                >
                  {sliderData}
                </span>
              ) : (
                <span
                  className="range_tootip range_tootip_knee"
                  style={{ left: `${tpPosition}` }}
                >
                  {sliderData}
                </span>
              )}
            </div>
          )}
        </div>
        {colorcode === "1" ? (
          <>
            {type === "image" && (
              <span className="happy_face">
                <img src={happyFace} alt="icon" />
              </span>
            )}
          </>
        ) : (
          <>
            {type === "image" && (
              <span className="sad_face">
                <img src={sadFace} alt="icon" />
              </span>
            )}
          </>
        )}
        {/* {type === "image" && <span className="sad_face"><img src={sadFace} alt="icon" /></span>} */}
        {type === "text" && (
          <span className="unstable_txt">
            {lang === "ara" ? leftTitle : rightTitle}
          </span>
        )}
        {type === "both" && (
          <>
            <span className="sad_face">
              <img src={sadFace} alt="icon" />
            </span>
            <span className="unstable_txt mt-3  ">
              {lang === "ara" ? leftTitle : rightTitle}
            </span>
          </>
        )}
        {type === "both1" && (
          <>
            <span className="sad_face">
              <img src={happyFace} alt="icon" />
            </span>
            <span className="unstable_txt mt-3  ">
              {lang === "ara" ? leftTitle : rightTitle}
            </span>
          </>
        )}
      </div>
    </div>
  );
};
RangeSlider.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
RangeSlider.defaultProps = {
  value: "",
  name: "",
  label: "",
  id: "",
  min: "",
  max: "",
  step: "",
  onChange: "",
  onMouseUp: "",
  formGroupClass: "",
  type: "",
  leftTitle: "",
  rightTitle: "",
};
export default RangeSlider;
