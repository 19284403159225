import React, { useMemo, useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import InputField from "../../../custom/inputfield/InputField";
import RangeSlider from "../../../custom/range-slider/RangeSlider";
import { useDispatch, useSelector } from "react-redux";
import "./Sacs.css";
import Tab from "../../tab-section/ShoulderTab"
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../custom/toaster/CustomToaster";
import {
  updateSACSFunctionFieldScore,
  updateSACSFunctionScores,
  updateShoulderFunctionScores,
  updateShoulder,
  updateShoulderPainScores,
  updateWOSIFunctionScores,
  updateSatisfactionFunctionScores,
  updateTotalScores,
  updateAllData,
  updateFeedbackFunctionScores
} from "../../../../redux/actions/shoulderAction";

// import Button from "../../custom/button/Button";
import useAxios from "../../../../axiosinstance";
import useTransition from '../../../customHooks/translations'
import { Validators } from "../../../../utilities/Validator";
import Checkbox from "../../../custom/checkbox/Checkbox";
import NameCustom from "../../../custom/name/NameCustom";

function Sacs() {
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const translation = useTransition();
  const dispatch = useDispatch();
  const axiosinstance = useAxios();
  const { rId } = useParams();
  const shoulserScore = useSelector(
    (state) => state.shoulderScore.shoulserScore
  );
  const [chk, setChk] = useState(false);
  const [updateTotal, setUpdateTotal] = useState(false);
  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=4`)
      .then((res) => {
        dispatch(updateShoulder(res.data.data2[0].shoulserScore));
        dispatch(updateShoulderPainScores(res.data.data2[0].painScore));
        dispatch(updateShoulderFunctionScores(res.data.data2[0].functionScore));
        dispatch(updateWOSIFunctionScores(res.data.data2[0].wosiScore));
        dispatch(updateSACSFunctionScores(res.data.data2[0].sacsScore));
        dispatch(
          updateSatisfactionFunctionScores(res.data.data2[0].safisScore)
        );
        dispatch(updateTotalScores(res.data.data2[0].TotalScores));
        dispatch(updateFeedbackFunctionScores(res.data.data2[0].doctorFeedback))
        setChk(true);
      }).catch((err) => {

        navigate('/un-authorized-access');

      });
  };

  useEffect(() => {
    save();
  }, []);
  const [isLoading, setIsLoading] = useState(true);
  const [sacsQL1, setSacsQL1] = useState("0");
  const [sacsQR1, setSacsQR1] = useState("0");
  const [sacsQL2, setSacsQL2] = useState("0");
  const [sacsQR2, setSacsQR2] = useState("0");
  const [sacsQL3, setSacsQL3] = useState("0");
  const [sacsQR3, setSacsQR3] = useState("0");
  const [sacsQL4, setSacsQL4] = useState("0");
  const [sacsQR4, setSacsQR4] = useState("0");
  const [sacsQL5, setSacsQL5] = useState("0");
  const [sacsQR5, setSacsQR5] = useState("0");
  const [sacsQL6, setSacsQL6] = useState("0");
  const [sacsQR6, setSacsQR6] = useState("0");
  const [sacsQL7, setSacsQL7] = useState("0");
  const [sacsQR7, setSacsQR7] = useState("0");
  const [sacsQL8, setSacsQL8] = useState("0");
  const [sacsQR8, setSacsQR8] = useState("0");
  const [sacsQL9, setSacsQL9] = useState("0");
  const [sacsQR9, setSacsQR9] = useState("0");
  const [sacsQL10, setSacsQL10] = useState("0");
  const [sacsQR10, setSacsQR10] = useState("0");
  const [sacsQL11, setSacsQL11] = useState("0");
  const [sacsQR11, setSacsQR11] = useState("0");
  const [sacsQL12, setSacsQL12] = useState("0");
  const [sacsQR12, setSacsQR12] = useState("0");
  const [sacsQL13, setSacsQL13] = useState("0");
  const [sacsQR13, setSacsQR13] = useState("0");
  const [sacsQL14, setSacsQL14] = useState("0");
  const [sacsQR14, setSacsQR14] = useState("0");
  const [sacsQL15, setSacsQL15] = useState("0");
  const [sacsQR15, setSacsQR15] = useState("0");
  const [sacsQL16, setSacsQL16] = useState("0");
  const [sacsQR16, setSacsQR16] = useState("0");
  const [sacsQL17, setSacsQL17] = useState("0");
  const [sacsQR17, setSacsQR17] = useState("0");
  const [sacsQL18, setSacsQL18] = useState("0");
  const [sacsQR18, setSacsQR18] = useState("0");
  const [sacsQL19, setSacsQL19] = useState("0");
  const [sacsQR19, setSacsQR19] = useState("0");
  const [sacsQL20, setSacsQL20] = useState("0");
  const [sacsQR20, setSacsQR20] = useState("0");

  const [sacsQL21, setSacsQL21] = useState("100");
  const [sacsQL21Err, setSacsQL21Err] = useState(false);

  const [sacsQR21, setSacsQR21] = useState("100");
  const [sacsQR21Err, setSacsQR21Err] = useState(false);

  const [check1, setcheck1] = useState(false)
  const [check2, setcheck2] = useState(false)
  const [check3, setcheck3] = useState(false)
  const [check4, setcheck4] = useState(false)
  const [check5, setcheck5] = useState(false)
  const [check6, setcheck6] = useState(false)
  const [check7, setcheck7] = useState(false)
  const [check8, setcheck8] = useState(false)

  const navigate = useNavigate();
  const sacsQL21Ref = useRef()
  const sacsQR21Ref = useRef()

  const sacsScore = useSelector((state) => state.shoulderScore.sacsScore[0]);
  const json = useSelector((state) => state.shoulderScore);
  useEffect(() => {
    setSacsQL1(sacsScore.sacs_q1_left);
    setSacsQR1(sacsScore.sacs_q1_right);
    setSacsQL2(sacsScore.sacs_q2_left);
    setSacsQR2(sacsScore.sacs_q2_right);
    setSacsQL3(sacsScore.sacs_q3_left);
    setSacsQR3(sacsScore.sacs_q3_right);
    setSacsQL4(sacsScore.sacs_q4_left);
    setSacsQR4(sacsScore.sacs_q4_right);
    setSacsQL5(sacsScore.sacs_q5_left);
    setSacsQR5(sacsScore.sacs_q5_right);
    setSacsQL6(sacsScore.sacs_q6_left);
    setSacsQR6(sacsScore.sacs_q6_right);
    setSacsQL7(sacsScore.sacs_q7_left);
    setSacsQR7(sacsScore.sacs_q7_right);
    setSacsQL8(sacsScore.sacs_q8_left);
    setSacsQR8(sacsScore.sacs_q8_right);
    setSacsQL9(sacsScore.sacs_q9_left);
    setSacsQR9(sacsScore.sacs_q9_right);
    setSacsQL10(sacsScore.sacs_q10_left);
    setSacsQR10(sacsScore.sacs_q10_right);
    setSacsQL11(sacsScore.sacs_q11_left);
    setSacsQR11(sacsScore.sacs_q11_right);
    setSacsQL12(sacsScore.sacs_q12_left);
    setSacsQR12(sacsScore.sacs_q12_right);
    setSacsQL13(sacsScore.sacs_q13_left);
    setSacsQR13(sacsScore.sacs_q13_right);
    setSacsQL14(sacsScore.sacs_q14_left);
    setSacsQR14(sacsScore.sacs_q14_right);
    setSacsQL15(sacsScore.sacs_q15_left);
    setSacsQR15(sacsScore.sacs_q15_right);
    setSacsQL16(sacsScore.sacs_q16_left);
    setSacsQR16(sacsScore.sacs_q16_right);
    setSacsQL17(sacsScore.sacs_q17_left);
    setSacsQR17(sacsScore.sacs_q17_right);
    setSacsQL18(sacsScore.sacs_q18_left);
    setSacsQR18(sacsScore.sacs_q18_right);
    setSacsQL19(sacsScore.sacs_q19_left);
    setSacsQR19(sacsScore.sacs_q19_right);
    setSacsQL20(sacsScore.sacs_q20_left);
    setSacsQR20(sacsScore.sacs_q20_right);
    setSacsQL21(sacsScore.sacs_q21_left);
    setSacsQR21(sacsScore.sacs_q21_right);
    setcheck1(sacsScore.check1);
    setcheck2(sacsScore.check2);
    setcheck3(sacsScore.check3);
    setcheck4(sacsScore.check4);
    setcheck5(sacsScore.check5);
    setcheck6(sacsScore.check6);
    setcheck7(sacsScore.check7);
    setcheck8(sacsScore.check8);
  }, [sacsScore]);

  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true" ? "1" : "0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [sacsScore, updateTotal, doctorEnable]);
  const handleSacsQL1 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q1_left",
        value: e,
      })
    );
    setSacsQL1(e);
  };
  const handleSacsQR1 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q1_right",
        value: e,
      })
    );
    setSacsQR1(e);
  };

  const handleSacsQL2 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q2_left",
        value: e,
      })
    );
    setSacsQL2(e);
  };
  const handleSacsQR2 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q2_right",
        value: e,
      })
    );
    setSacsQR2(e);
  };

  const handleSacsQL3 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q3_left",
        value: e,
      })
    );
    setSacsQL3(e);
  };
  const handleSacsQR3 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q3_right",
        value: e,
      })
    );
    setSacsQR3(e);
  };

  const handleSacsQL4 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q4_left",
        value: e,
      })
    );
    setSacsQL4(e);
  };
  const handleSacsQR4 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q4_right",
        value: e,
      })
    );
    setSacsQR4(e);
  };

  const handleSacsQL5 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q5_left",
        value: e,
      })
    );
    setSacsQL5(e);
  };
  const handleSacsQR5 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q5_right",
        value: e,
      })
    );
    setSacsQR5(e);
  };

  const handleSacsQL6 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q6_left",
        value: e,
      })
    );
    setSacsQL6(e);
  };
  const handleSacsQR6 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q6_right",
        value: e,
      })
    );
    setSacsQR6(e);
  };

  const handleSacsQL7 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q7_left",
        value: e,
      })
    );
    setSacsQL7(e);
  };
  const handleSacsQR7 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q7_right",
        value: e,
      })
    );
    setSacsQR7(e);
  };

  const handleSacsQL8 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q8_left",
        value: e,
      })
    );
    setSacsQL8(e);
  };
  const handleSacsQR8 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q8_right",
        value: e,
      })
    );
    setSacsQR8(e);
  };

  const handleSacsQL9 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q9_left",
        value: e,
      })
    );
    setSacsQL9(e);
  };
  const handleSacsQR9 = (e) => {
    setSacsQR9(e);
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q9_right",
        value: e,
      })
    );
  };

  const handleSacsQL10 = (e) => {
    setSacsQL10(e);
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q10_left",
        value: e,
      })
    );
  };
  const handleSacsQR10 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q10_right",
        value: e,
      })
    );
    setSacsQR10(e);
  };

  const handleSacsQL11 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q11_left",
        value: e,
      })
    );
    setSacsQL11(e);
  };
  const handleSacsQR11 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q11_right",
        value: e,
      })
    );
    setSacsQR11(e);
  };

  const handleSacsQL12 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q12_left",
        value: e,
      })
    );
    setSacsQL12(e);
  };
  const handleSacsQR12 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q12_right",
        value: e,
      })
    );
    setSacsQR12(e);
  };

  const handleSacsQL13 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q13_left",
        value: e,
      })
    );
    setSacsQL13(e);
  };
  const handleSacsQR13 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q13_right",
        value: e,
      })
    );
    setSacsQR13(e);
  };

  const handleSacsQL14 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q14_left",
        value: e,
      })
    );
    setSacsQL14(e);
  };
  const handleSacsQR14 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q14_right",
        value: e,
      })
    );
    setSacsQR14(e);
  };

  const handleSacsQL15 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q15_left",
        value: e,
      })
    );
    setSacsQL15(e);
  };
  const handleSacsQR15 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q15_right",
        value: e,
      })
    );
    setSacsQR15(e);
  };

  const handleSacsQL16 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q16_left",
        value: e,
      })
    );
    setSacsQL16(e);
  };
  const handleSacsQR16 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q16_right",
        value: e,
      })
    );
    setSacsQR16(e);
  };

  const handleSacsQL17 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q17_left",
        value: e,
      })
    );
    setSacsQL17(e);
  };
  const handleSacsQR17 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q17_right",
        value: e,
      })
    );
    setSacsQR17(e);
  };

  const handleSacsQL18 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q18_left",
        value: e,
      })
    );
    setSacsQL18(e);
  };
  const handleSacsQR18 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q18_right",
        value: e,
      })
    );
    setSacsQR18(e);
  };

  const handleSacsQL19 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q19_left",
        value: e,
      })
    );
    setSacsQL19(e);
  };
  const handleSacsQR19 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q19_right",
        value: e,
      })
    );
    setSacsQR19(e);
  };

  const handleSacsQL20 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q20_left",
        value: e,
      })
    );
    setSacsQL20(e);
  };
  const handleSacsQR20 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q20_right",
        value: e,
      })
    );
    setSacsQR20(e);
  };

  const handleSacsQL21 = (e, err) => {
    const newValue = e.replace(/[^0-9]/g, "");
    setSacsQL21(newValue);
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q21_left",
        value: e.replace(/[^0-9]/g, ""),
      })
    );

    setSacsQL21Err(err);
  };
  useEffect(() => {
    if (sacsQL21 > 100) {
      dispatch(
        updateSACSFunctionFieldScore({
          key: "sacs_q21_left",
          value: 100,
        })
      );
      setSacsQR21(100)
    }
  }, [sacsQL21])
  const handleSacsQR21 = (e, err) => {
    const newValue = e.replace(/[^0-9]/g, "");
    setSacsQR21(newValue);
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q21_right",
        value: e.replace(/[^0-9]/g, ""),
      })
    );

    setSacsQR21Err(err);

  };
  useEffect(() => {
    if (sacsQR21 > 100) {
      dispatch(
        updateSACSFunctionFieldScore({
          key: "sacs_q21_right",
          value: 100,
        })
      );
      setSacsQR21(100)
    }
  }, [sacsQR21])
  const handleCheck1 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "check1",
        value: e,
      })
    );

    setcheck1(e)
  }
  const handleCheck2 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "check2",
        value: e,
      })
    );
    setcheck2(e)
  }
  const handleCheck3 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "check3",
        value: e,
      })
    );
    setcheck3(e)
  }
  const handleCheck4 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "check4",
        value: e,
      })
    );
    setcheck4(e)
  }
  const handleCheck5 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "check5",
        value: e,
      })
    );
    setcheck5(e)
  }
  const handleCheck6 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "check6",
        value: e,
      })
    );
    setcheck6(e)
  }
  const handleCheck7 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "check7",
        value: e,
      })
    );
    setcheck7(e)
  }
  const handleCheck8 = (e) => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "check8",
        value: e,
      })
    );
    setcheck8(e)
  }
  const continueRedirect = (e) => {
    if (sacsQL21Err == false && sacsQR21Err == false) {
      // dispatch(
      //   updateSACSFunctionScores([
      //     {
      //       sacs_q1_left: sacsQL1,
      //       sacs_q1_right: sacsQR1,
      //       sacs_q2_left: sacsQL2,
      //       sacs_q2_right: sacsQR2,
      //       sacs_q3_left: sacsQL3,
      //       sacs_q3_right: sacsQR3,
      //       sacs_q4_left: sacsQL4,
      //       sacs_q4_right: sacsQR4,
      //       sacs_q5_left: sacsQL5,
      //       sacs_q5_right: sacsQR5,
      //       sacs_q6_left: sacsQL6,
      //       sacs_q6_right: sacsQR6,
      //       sacs_q7_left: sacsQL7,
      //       sacs_q7_right: sacsQR7,
      //       sacs_q8_left: sacsQL8,
      //       sacs_q8_right: sacsQR8,
      //       sacs_q9_left: sacsQL9,
      //       sacs_q9_right: sacsQR9,
      //       sacs_q10_left: sacsQL10,
      //       sacs_q10_right: sacsQR10,
      //       sacs_q11_left: sacsQL11,
      //       sacs_q11_right: sacsQR11,
      //       sacs_q12_left: sacsQL12,
      //       sacs_q12_right: sacsQR12,
      //       sacs_q13_left: sacsQL13,
      //       sacs_q13_right: sacsQR13,
      //       sacs_q14_left: sacsQL14,
      //       sacs_q14_right: sacsQR14,
      //       sacs_q15_left: sacsQL15,
      //       sacs_q15_right: sacsQR15,
      //       sacs_q16_left: sacsQL16,
      //       sacs_q16_right: sacsQR16,
      //       sacs_q17_left: sacsQL17,
      //       sacs_q17_right: sacsQR17,
      //       sacs_q18_left: sacsQL18,
      //       sacs_q18_right: sacsQR18,
      //       sacs_q19_left: sacsQL19,
      //       sacs_q19_right: sacsQR19,
      //       sacs_q20_left: sacsQL20,
      //       sacs_q20_right: sacsQR20,
      //       sacs_q21_left: sacsQL21,
      //       sacs_q21_right: sacsQR21,
      //       check1: check1,
      //       check2: check2,
      //       check3: check3,
      //       check4: check4,
      //       check5: check5,
      //       check6: check6,
      //       check7: check7,
      //       check8: check8,
      //     },
      //   ])
      // );
      navigate("/satisfaction/" + rId);
    } else {
      return
    }


  };
  let akpt = searchParams.get("akpt");

  const [msg, setMsg] = useState('');
  const [msgType, setmsgType] = useState('');
  const handleSubmit = () => {
    updateAllData(json, dispatch)
    setUpdateTotal(true)
    setUpdateTotal(!updateTotal);
    setMsg(translation.update)
    setmsgType('success')
   setTimeout(() => {
     let view_circular=  sessionStorage.getItem("view_circular")
      // window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" + akpt
      // window.location.href = process.env.REACT_APP_DOCTOR_URL +`view-management-plan/${akpt}/${window.btoa(
      //   rId
      // )}/?org=${window.btoa(sessionStorage.getItem("orgid")||'')}&formType=${
      //   window.btoa('4') || ""
      // }&view_circular=${view_circular==='false'?'false':'true'}`
      window.location.href = process.env.REACT_APP_DOCTOR_URL +`patient-details/${akpt}?formid=4`
      setMsg('')
      setmsgType('success')
    }, 2000);
  }
  
  const handleNext = (e) => {
    navigate(`/satisfaction/${rId}?d=true&akpt=${akpt}`);

  }
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const Back = () => {
    navigate(-1, { replace: false });
  }

  useEffect(() => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q12_right",
        value: "0",
      })
    );
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q12_left",
        value: "0",
      })
    );
  }, [check1])
  // useEffect(() => {
  //   dispatch(
  //     updateSACSFunctionFieldScore({
  //       key: "sacs_q12_left",
  //       value: "0",
  //     })
  //   );

  // }, [check2])
  useEffect(() => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q13_right",
        value: "0",
      })
    );
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q13_left",
        value: "0",
      })
    );

  }, [check3])
  // useEffect(() => {
  //   dispatch(
  //     updateSACSFunctionFieldScore({
  //       key: "sacs_q13_left",
  //       value: "0",
  //     })
  //   );

  // }, [check4])
  useEffect(() => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q14_right",
        value: "0",
      })
    );
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q14_left",
        value: "0",
      })
    );

  }, [check5])
  // useEffect(() => {
  //   dispatch(
  //     updateSACSFunctionFieldScore({
  //       key: "sacs_q14_left",
  //       value: "0",
  //     })
  //   );

  // }, [check6])
  useEffect(() => {
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q15_right",
        value: "0",
      })
    );
    dispatch(
      updateSACSFunctionFieldScore({
        key: "sacs_q15_left",
        value: "0",
      })
    );
  }, [check7])
  // useEffect(() => {
  //   dispatch(
  //     updateSACSFunctionFieldScore({
  //       key: "sacs_q15_left",
  //       value: "0",
  //     })
  //   );

  // }, [check8])
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="sacs_screen mb_100">
          <div className="panel">
            <div className="panel-body p_25">
              {doctorEnable === "true" && (
                <Tab rId={rId} />
              )}
              <CustomToaster msg={msg} msgType={msgType} />
              <NameCustom />
              <h4 className="ph_title f_40">{translation.patient_shoulder_sacs} </h4>

              <div className="function_formtop">
                <div className="row">
                  <div className="col-md-12 col-sm-12 pt_20">
                    <strong>
                      {translation.patient_shoulder_sacs_explanation}
                    </strong>
                    <p className="info-note ">
                      {translation.patient_shoulder_sacs_note}{" "}
                    </p>
                  </div>
                </div>
                <h6 class="label-title">{translation.patient_shoulder_sacs_sectionA}</h6>
                <div className="row">
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      A1. {translation.patient_shoulder_sacs_a1}
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="sacsQR1"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_sacs_a1_a1}
                        rightTitle={translation.patient_shoulder_sacs_a1_a2}
                        value={sacsQR1}
                        onChange={handleSacsQR1}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="sacsQL1"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_sacs_a1_a1}
                        rightTitle={translation.patient_shoulder_sacs_a1_a2}
                        value={sacsQL1}
                        onChange={handleSacsQL1}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      A2. {translation.patient_shoulder_sacs_a2}
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="sacsQR2"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_sacs_a2_a1}
                        rightTitle={translation.patient_shoulder_sacs_a2_a2}
                        value={sacsQR2}
                        onChange={handleSacsQR2}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="sacsQL2"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_sacs_a2_a1}
                        rightTitle={translation.patient_shoulder_sacs_a2_a2}
                        value={sacsQL2}
                        onChange={handleSacsQL2}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      A3. {translation.patient_shoulder_sacs_a3}
                      <small>({translation.patient_shoulder_sacs_a3_note}).</small>
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="sacsQR3"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_sacs_a3_a1}
                        rightTitle={translation.patient_shoulder_sacs_a3_a2}
                        value={sacsQR3}
                        onChange={handleSacsQR3}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="sacsQL3"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_sacs_a3_a1}
                        rightTitle={translation.patient_shoulder_sacs_a3_a2}
                        value={sacsQL3}
                        onChange={handleSacsQL3}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      A4. {translation.patient_shoulder_sacs_a4}
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="sacsQR4"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_sacs_a4_a1}
                        rightTitle={translation.patient_shoulder_sacs_a4_a2}
                        value={sacsQR4}
                        onChange={handleSacsQR4}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="sacsQL4"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_sacs_a4_a1}
                        rightTitle={translation.patient_shoulder_sacs_a4_a2}
                        value={sacsQL4}
                        onChange={handleSacsQL4}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                </div>
              </div>
              <div className="function_formtop">
                <h6 class="label-title">{translation.patient_shoulder_sacs_sectionB}</h6>
                <div className="row">
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      B1. {translation.patient_shoulder_sacs_b1}
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="sacsQR5"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_sacs_b1_a1}
                        rightTitle={translation.patient_shoulder_sacs_b1_a2}
                        value={sacsQR5}
                        onChange={handleSacsQR5}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="sacsQL5"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_sacs_b1_a1}
                        rightTitle={translation.patient_shoulder_sacs_b1_a2}
                        value={sacsQL5}
                        onChange={handleSacsQL5}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      B2. {translation.patient_shoulder_sacs_b2}
                      <small>
                        ({translation.patient_shoulder_sacs_b2_note}).
                      </small>
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="sacsQR6"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_sacs_b2_a1}
                        rightTitle={translation.patient_shoulder_sacs_b2_a2}
                        value={sacsQR6}
                        onChange={handleSacsQR6}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="sacsQL6"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_sacs_b2_a1}
                        rightTitle={translation.patient_shoulder_sacs_b2_a2}
                        value={sacsQL6}
                        onChange={handleSacsQL6}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      B3. {translation.patient_shoulder_sacs_b3}
                      <small>({translation.patient_shoulder_sacs_b3_note}).</small>
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="sacsQR7"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_sacs_b3_a1}
                        rightTitle={translation.patient_shoulder_sacs_b3_a2}
                        value={sacsQR7}
                        onChange={handleSacsQR7}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="sacsQL7"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_sacs_b3_a1}
                        rightTitle={translation.patient_shoulder_sacs_b3_a2}
                        value={sacsQL7}
                        onChange={handleSacsQL7}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      B4. {translation.patient_shoulder_sacs_b4}
                      <small>({translation.patient_shoulder_sacs_b4_note}).</small>
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="sacsQR8"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_sacs_b4_a1}
                        rightTitle={translation.patient_shoulder_sacs_b4_a2}
                        value={sacsQR8}
                        onChange={handleSacsQR8}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="sacsQL8"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_sacs_b4_a1}
                        rightTitle={translation.patient_shoulder_sacs_b4_a2}
                        value={sacsQL8}
                        onChange={handleSacsQL8}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      B5. {translation.patient_shoulder_sacs_b5}
                      <small>
                        ({translation.patient_shoulder_sacs_b5_note})
                      </small>
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="sacsQR9"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_sacs_b5_a1}
                        rightTitle={translation.patient_shoulder_sacs_b5_a2}
                        value={sacsQR9}
                        onChange={handleSacsQR9}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="sacsQL9"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_sacs_b5_a1}
                        rightTitle={translation.patient_shoulder_sacs_b5_a2}
                        value={sacsQL9}
                        onChange={handleSacsQL9}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      B6. {translation.patient_shoulder_sacs_b6}
                      <small>({translation.patient_shoulder_sacs_b6_note}).</small>
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="sacsQR10"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_sacs_b6_a1}
                        rightTitle={translation.patient_shoulder_sacs_b6_a2}
                        value={sacsQR10}
                        onChange={handleSacsQR10}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="sacsQL10"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_sacs_b6_a1}
                        rightTitle={translation.patient_shoulder_sacs_b6_a2}
                        value={sacsQL10}
                        onChange={handleSacsQL10}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      B7. {translation.patient_shoulder_sacs_b7}
                      <small>
                        ({translation.patient_shoulder_sacs_b7_note})
                      </small>
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="sacsQR11"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_sacs_b7_a1}
                        rightTitle={translation.patient_shoulder_sacs_b7_a2}
                        value={sacsQR11}
                        onChange={handleSacsQR11}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="sacsQL11"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_sacs_b7_a1}
                        rightTitle={translation.patient_shoulder_sacs_b7_a2}
                        value={sacsQL11}
                        onChange={handleSacsQL11}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      B8. {translation.patient_shoulder_sacs_b8}
                    </label>
                    <div className="row">
                      <Checkbox
                        label={translation.patient_shoulder_sacs_b8_a3}
                        value={check1}
                        selected={check1}
                        onChange={handleCheck1}
                      />
                    </div>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <>
                        <RangeSlider
                          id="sacsQR12"
                          min="0"
                          max="10"
                          step="1"
                          type="text"
                          label={translation.Right_Arm}
                          leftTitle={translation.patient_shoulder_sacs_b8_a1}
                          rightTitle={translation.patient_shoulder_sacs_b8_a2}
                          value={sacsQR12}
                          onChange={handleSacsQR12}
                          formGroupClass="label_style mb_40"
                          view={check1}
                        />

                      </>
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <>
                        <RangeSlider
                          id="sacsQL12"
                          min="0"
                          max="10"
                          step="1"
                          type="text"
                          label={translation.Left_Arm}
                          leftTitle={translation.patient_shoulder_sacs_b8_a1}
                          rightTitle={translation.patient_shoulder_sacs_b8_a2}
                          value={sacsQL12}
                          onChange={handleSacsQL12}
                          formGroupClass="label_style mb_40"
                          view={check1}
                        />
                        {/* <div className="row">
                          <Checkbox
                            label={translation.patient_shoulder_sacs_b8_a3}
                            value={check2}
                            selected={check2}
                            onChange={handleCheck2}
                          />
                        </div> */}
                      </>
                    ) : null}

                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      B9. {translation.patient_shoulder_sacs_b9}
                    </label>
                    <div className="row">
                      <Checkbox
                        label={translation.patient_shoulder_sacs_b8_a3}
                        value={check3}
                        selected={check3}
                        onChange={handleCheck3}
                      />
                    </div>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <>
                        <RangeSlider
                          id="sacsQR13"
                          min="0"
                          max="10"
                          step="1"
                          type="text"
                          label={translation.Right_Arm}
                          leftTitle={translation.patient_shoulder_sacs_b9_a1}
                          rightTitle={translation.patient_shoulder_sacs_b9_a2}
                          value={sacsQR13}
                          onChange={handleSacsQR13}
                          formGroupClass="label_style mb_40"
                          view={check3}
                        />

                      </>
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <>
                        <RangeSlider
                          id="sacsQL13"
                          min="0"
                          max="10"
                          step="1"
                          type="text"
                          label={translation.Left_Arm}
                          leftTitle={translation.patient_shoulder_sacs_b9_a1}
                          rightTitle={translation.patient_shoulder_sacs_b9_a2}
                          value={sacsQL13}
                          onChange={handleSacsQL13}
                          formGroupClass="label_style mb_40"
                          view={check3}
                        />
                        {/* <div className="row">
                          <Checkbox
                            label={translation.patient_shoulder_sacs_b8_a3}
                            value={check4}
                            selected={check4}
                            onChange={handleCheck4}
                          />
                        </div> */}
                      </>
                    ) : null}

                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      B10. {translation.patient_shoulder_sacs_b10}
                    </label>
                    <div className="row">
                      <Checkbox
                        label={translation.patient_shoulder_sacs_b8_a3}
                        value={check5}
                        selected={check5}
                        onChange={handleCheck5}
                      />
                    </div>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <>
                        <RangeSlider
                          id="sacsQR14"
                          min="0"
                          max="10"
                          step="1"
                          type="text"
                          label={translation.Right_Arm}
                          leftTitle={translation.patient_shoulder_sacs_b10_a1}
                          rightTitle={translation.patient_shoulder_sacs_b10_a2}
                          value={sacsQR14}
                          onChange={handleSacsQR14}
                          formGroupClass="label_style mb_40"
                          view={check5}
                        />

                      </>
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <>
                        <RangeSlider
                          id="sacsQL14"
                          min="0"
                          max="10"
                          step="1"
                          type="text"
                          label={translation.Left_Arm}
                          leftTitle={translation.patient_shoulder_sacs_b10_a1}
                          rightTitle={translation.patient_shoulder_sacs_b10_a2}
                          value={sacsQL14}
                          onChange={handleSacsQL14}
                          formGroupClass="label_style mb_40"
                          // view={check6}
                          view={check5}
                        />
                        {/* <div className="row">
                          <Checkbox
                            label={translation.patient_shoulder_sacs_b8_a3}
                            value={check6}
                            selected={check6}
                            onChange={handleCheck6}
                          />
                        </div> */}
                      </>
                    ) : null}

                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      B11. {translation.patient_shoulder_sacs_b11}
                    </label>
                    <div className="row">
                      <Checkbox
                        label={translation.patient_shoulder_sacs_b8_a3}
                        value={check7}
                        selected={check7}
                        onChange={handleCheck7}
                      />
                    </div>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <>
                        <RangeSlider
                          id="sacsQR15"
                          min="0"
                          max="10"
                          step="1"
                          type="text"
                          label={translation.Right_Arm}
                          leftTitle={translation.patient_shoulder_sacs_b11_a1}
                          rightTitle={translation.patient_shoulder_sacs_b11_a2}
                          value={sacsQR15}
                          onChange={handleSacsQR15}
                          formGroupClass="label_style mb_40"
                          view={check7}
                        />

                      </>
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <>
                        <RangeSlider
                          id="sacsQL15"
                          min="0"
                          max="10"
                          step="1"
                          type="text"
                          label={translation.Left_Arm}
                          leftTitle={translation.patient_shoulder_sacs_b11_a1}
                          rightTitle={translation.patient_shoulder_sacs_b11_a2}
                          value={sacsQL15}
                          onChange={handleSacsQL15}
                          formGroupClass="label_style mb_40"
                          // view={check8}
                          view={check7}
                        />
                        {/* <div className="row">
                          <Checkbox
                            label={translation.patient_shoulder_sacs_b8_a3}
                            value={check8}
                            selected={check8}
                            onChange={handleCheck8}
                          />
                        </div> */}
                      </>
                    ) : null}

                  </div>
                </div>
              </div>
              <div className="function_formtop">
                <h6 class="label-title">
                  {translation.patient_shoulder_sacs_sectionC}
                </h6>
                <div className="row">
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      C1. {translation.patient_shoulder_sacs_c1}<small>({translation.patient_shoulder_sacs_c1_note}).</small>
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="sacsQR16"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_sacs_c1_a1}
                        rightTitle={translation.patient_shoulder_sacs_c1_a2}
                        value={sacsQR16}
                        onChange={handleSacsQR16}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="sacsQL16"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_sacs_c1_a1}
                        rightTitle={translation.patient_shoulder_sacs_c1_a2}
                        value={sacsQL16}
                        onChange={handleSacsQL16}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      C2. {translation.patient_shoulder_sacs_c2}
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="sacsQR17"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_sacs_c2_a1}
                        rightTitle={translation.patient_shoulder_sacs_c2_a2}
                        value={sacsQR17}
                        onChange={handleSacsQR17}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="sacsQL17"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_sacs_c2_a1}
                        rightTitle={translation.patient_shoulder_sacs_c2_a2}
                        value={sacsQL17}
                        onChange={handleSacsQL17}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      C3. {translation.patient_shoulder_sacs_c3}
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="sacsQR18"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_sacs_c3_a1}
                        rightTitle={translation.patient_shoulder_sacs_c3_a2}
                        value={sacsQR18}
                        onChange={handleSacsQR18}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="sacsQL18"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_sacs_c3_a1}
                        rightTitle={translation.patient_shoulder_sacs_c3_a2}
                        value={sacsQL18}
                        onChange={handleSacsQL18}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      C4. {translation.patient_shoulder_sacs_c4}
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="sacsQR19"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_sacs_c4_a1}
                        rightTitle={translation.patient_shoulder_sacs_c4_a2}
                        value={sacsQR19}
                        onChange={handleSacsQR19}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="sacsQL19"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_sacs_c4_a1}
                        rightTitle={translation.patient_shoulder_sacs_c4_a2}
                        value={sacsQL19}
                        onChange={handleSacsQL19}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      C5. {translation.patient_shoulder_sacs_c5}
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="sacsQR20"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_sacs_c5_a1}
                        rightTitle={translation.patient_shoulder_sacs_c5_a2}
                        value={sacsQR20}
                        onChange={handleSacsQR20}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="sacsQL20"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_sacs_c5_a1}
                        rightTitle={translation.patient_shoulder_sacs_c5_a2}
                        value={sacsQL20}
                        onChange={handleSacsQL20}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                  {/* <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      {translation.C5unhappy_shoulder}
                    </label>
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="sacsQL20"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.Extremely_happy}
                        rightTitle={translation.Extremely_unhappy}
                        value={sacsQL20}
                        onChange={handleSacsQL20}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="sacsQR20"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.Extremely_happy}
                        rightTitle={translation.Extremely_unhappy}
                        value={sacsQR20}
                        onChange={handleSacsQR20}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                  </div> */}
                  <div className="col-md-12 col-sm-12 mb_20">
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <>
                        <div className="row align-items-center">
                          <div className="col-8">
                            <label className="lab_title mb_20">
                              C6. {translation.patient_shoulder_sacs_c6}
                            </label>
                          </div>
                          <div className="col-4">
                            <InputField
                              label={translation.Right_Arm}
                              value={sacsQR21}
                              type="text"
                              placeholder="_ _ _ %"
                              onChange={handleSacsQR21}
                              styleClass="mxw_120 br_15 ms-3"
                              ref={sacsQL21Ref}
                              formGroupClass="label_400 mxw_200 d-inline-block mr_30"
                              validators={[
                                {
                                  check: Validators.number,
                                  message: translation.Only_I,
                                },
                                {
                                  check: Validators.maxformtext,
                                  message: translation.greater_than_I,
                                },
                                {
                                  check: Validators.maxText100,
                                  message: translation.greater_than_I,
                                }

                              ]}
                            />

                            {/* <InputField
                              label={translation.Right_Arm}
                              value={sacsQR21}
                              type="text"
                              placeholder="_ _ _ %"
                              onChange={handleSacsQR21}
                              styleClass=" br_15 ms-3"
                              ref={sacsQR21Ref}
                              formGroupClass="label_400 mxw_200 d-inline-block"
                              validators={[
                                {
                                  check: Validators.number,
                                  message: translation.Only_I,
                                },
                                {
                                  check: Validators.maxformtext,
                                  message: translation.Only_II,
                                },
                                {
                                  check: Validators.maxText100,
                                  message: translation.greater_than_I,
                                }

                              ]}
                            /> */}
                          </div>

                        </div>


                      </>

                    ) : null}
                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">

                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <>
                        <div className="row align-items-center">
                          <div className="col-8">
                            <label className="lab_title mb_20">
                              C6. {translation.patient_shoulder_sacs_c6}
                            </label>
                          </div>
                          <div className="col-4">
                            <InputField
                              label={translation.Left_Arm}
                              value={sacsQL21}
                              type="text"
                              placeholder="_ _ _ %"
                              onChange={handleSacsQL21}
                              styleClass="mxw_120 br_15 ms-3"
                              ref={sacsQL21Ref}
                              formGroupClass="label_400 mxw_200 d-inline-block mr_30"
                              validators={[
                                {
                                  check: Validators.number,
                                  message: translation.Only_I,
                                },
                                {
                                  check: Validators.maxformtext,
                                  message: translation.greater_than_I,
                                },
                                {
                                  check: Validators.maxText100,
                                  message: translation.greater_than_I,
                                }

                              ]}
                            />
                          </div>
                        </div>
                      </>

                    ) : null}


                  </div>
                </div>
              </div>
              {doctorEnable === "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6">
                    {/* <Link to="/personal-data" className="assm_back_btn">
                       <img src={backIcon} alt="" />
                       <span>{translation.back_button}</span>
                     </Link> */}
                  </div>
                  <div className="col-md-6 col-sm-6 text-end">
                    <button
                      className="next_btn btn_fill me-3"
                      onClick={() => handleNext()}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                    <button
                      className="next_btn btn_fill"
                      onClick={() => handleSubmit()}
                    >
                      {translation.submit}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                  </div>
                </div>
              )}
              {doctorEnable !== "true" && (

                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">
                    <div
                      onClick={() => Back()}
                      className="assm_back_btn c_pointer"
                    >
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Sacs;
