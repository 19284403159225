import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useTranslation from "../../customHooks/translations";
import useAxios from '../../../axiosinstance';
import { useSearchParams } from "react-router-dom";
const NameCustom = ({ firstName,setMinDate ,formIds,rId}) => {

    const translation = useTranslation();
    const axiosInstance = useAxios()
    const { akPt, akDc, akOu, formId } = useParams();
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    let name = sessionStorage.getItem("name")
    let age = sessionStorage.getItem("age")
    let id = sessionStorage.getItem("ptid")
    let lang = sessionStorage.getItem("language")
    const is_doctor_form = queryParams.get('is_doctor_form');
    const [username, setUserName] = useState("")
    const [userage, setUserage] = useState("")
    
   
    const getData2 = async () => {
        try {
            const result = await axiosInstance.current.get(
                `extapp/forms/getUserLanguage?res_id=&pat_id=${id}&ak_dc=&is_patient=${is_doctor_form==="true"?"":"1"}`
            );
            // console.log(result)
            if (result.status === 200) {
                setUserName(result.data.name)
                setUserage(result.data.age)
                setMinDate(result.data.dob)
            }
        } catch (err) {
            console.log(err.Messages);
        } finally {
            console.log("api response finally");
        }
    };
    useEffect(() => {
        if( splitLocation[1] === "personal-data-edit"){
            return
        }else if(splitLocation[1] !== "patient-personal-data" && id){
        // if (id) {
            getData2()
        // }
    }
    }, [id])
   
   
  
    
    return (
        <div className="">
            <div className={` ${lang === "ara" ? 'float-start' : "float-end"} whiteboxSmallProfile c_pointer`}>
                <div className="d-flex ">
                    {splitLocation[1] === "personal-data" || splitLocation[1] === "patient-personal-data" || splitLocation[1] === "personal-data-edit"  ? <>
                        {firstName !== "" || username !== "" ? (

                            <label className="user-letter-smProfile text-capitalize f_24">
                                {firstName ? (firstName ? firstName.charAt(0) : "") : (username ? username.charAt(0) : "")}</label>
                        ) : ""}
                        <div className="ms-3">
                            <h6 className="text-capitalize">{`${firstName ? firstName : username} (${window.atob(akPt)})`}</h6>
                            <span className="">{translation.Age}: {age ? age : userage} {Number(userage) <= 1 ? translation.year_pdata : translation.years_pdata}</span>
                        </div></> :
                        <>
                      
                            {firstName !== "" || username !== "" ? (
                                <label className="user-letter-smProfile text-capitalize f_24"> 
                                 {firstName ? (firstName? firstName.charAt(0) : "") : (username ? username.charAt(0) : "")}</label>
                            ) : ""} 
                            <div className="ms-3">
                                <h6 className="text-capitalize">{`${firstName ? firstName : username} (${id})`}</h6>
                                <span className="">{translation.Age}: {age ? age : userage} {Number(userage) <= 1 ? translation.year_pdata : translation.years_pdata}</span>
                            </div>
                        </>
                    }

                </div>
            </div>
        </div>
    );
}

export default NameCustom;
